<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">协会状告养猪人索赔150万元，历思助力厦门首例环境公益诉讼案！</div>
      <p class="time">2018.09.21</p>
      <p class="space_content">
        近日，一场特殊的宣判在厦门中院举行。原告是福建省环保志愿者协会，被告是厦门一名男子叶某，叶某擅自开设大规模养殖场，造成大面积环境污染，被判向政府财政专户支付环境污染赔偿款91.75万元，用于环境生态修复，并在福建省级以上媒体向社会公开赔礼道歉。据悉，这也是厦门首例环境公益诉讼案。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/17-1.png')">
      </div>

      <p style="font-weight: 600;text-align: left">
        案件经过：
      </p>
      <p style="font-weight: 600;text-align: left">
        协会状告养猪人 索赔150万元
      </p>
      <p class="space_content">
        2003年至2005年，叶某在未取得相应的备案、审批手续情形下，擅自在承包地块上陆续建设猪舍、管理房及固化地用于开办规模化生猪养殖场，养殖场总占地面积达1.4万多平方米，生猪养殖规模近1.5万头。经环保机构测定，叶某养殖场距离四周村庄及道路的直线距离为500余米，位于政府规定的畜禽养殖禁养区内。
      </p>
      <p class="space_content">
        2016年11月，厦门市环保局同安分局对叶某作出责令改正违法行为决定书，责令其停止规模化生猪养殖，并自行处理生猪、关闭或拆除养殖场。2017年1月，该养殖场被拆除。同时，城管部门认为叶某涉嫌非法占用农用地罪，依法将该案移送公安机关处理。
      </p>
      <p class="space_content">
        2017年8月，该养殖场被确定为生猪退养转产转业项目。2018年1月，该生猪退养转产转业项目经验收合格。
      </p>
      <p class="space_content">
        2017年10月，福建省环保志愿者协会以叶某在政府规定的禁养区内长期从事大规模畜禽养殖，且未采取措施对畜禽的粪便、尸体和污水等废弃物进行处理，严重污染环境为由，向厦门中院提起诉讼。
        <span style="font-weight: 600">
           该协会要求叶某承担环境损害赔偿责任，赔偿金额预计150万元用于生态修复，并且还要在福建省级及以上的媒体公开道歉。
        </span>
      </p>

      <p style="font-weight: 600;text-align: left">
        审判遇上难题 环境污染如何计算
      </p>
      <p class="space_content">
        养殖场坐落于畜禽养殖禁养区内属实，不存在争议。但目前叶某规模化生猪养殖场所被拆除，几乎全部改造为蔬菜大棚设施，环境污染现场已灭失。并且在2018年1月，该生猪退养转产转业项目经验收已为合格。
        <span style="font-weight: 600">
          那么，环境污染损失如何计算？审判遇上了难题。
        </span>
      </p>
      <p class="space_content">
        为此厦门中院依职权决定将本案涉及的环境损害评估移送
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        进行鉴定。
        <span style="font-weight: 600">
          福建历思司法鉴定所
        </span>
        接到委托后，于2018年5月协同厦门中院的工作人员至现场勘验，对该养殖场的环境染污损失进行评估。
      </p>
      <p class="space_content">
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        经过勘查分析后，根据《最高人民法院关于审理环境侵权责任纠纷案件适用法律若干问题的解释》相关规定，采用虚拟治理成本法出具了环境损害后果的鉴定意见。鉴定意见表明：
        <span style="font-weight: 600;">
          叶某因生猪养殖违法排放污染物造成的环境损害总价值为91.75万元。
        </span>
      </p>


      <p style="font-weight: 600;text-align: left">
        环境公益诉讼案 最终审判结果
      </p>
      <p class="space_content">
        厦门中院经审理认为，叶某在禁养区内从事规模化生猪养殖，未按照相关规定进行环境影响评价且环境污染综合化处理，造成环境污染损害后果。
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定
        </span>
        所作为具备相关资格的司法鉴定机构，其适用虚拟治理成本法进行评估，并无不当，且符合本案的实际情形，
        <span style="font-weight: 600;">
          故其鉴定意见可以作为本案环境损害后果的认定依据。
        </span>
        厦门中院对该案作出一审判决，判令叶某支付环境污染
        <span style="font-weight: 600;">
          赔偿款91.75万元至政府财政专户用于环境生态修复，并在福建省级以上媒体向社会公开赔礼道歉。
        </span>
      </p>

      <p style="font-weight: 600;text-align: left">
        历思协同厦门中院的工作人员至现场勘查：
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/17-2.png')">
      </div>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/17-3.png')">
      </div>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/17-4.png')">
      </div>

      <p class="space_content">
        本案争议的主要焦点为叶某规模化生猪养殖环境污染现场灭失后，如何认定环境损害后果存在很大的争议。根据相关环境的法律法规标准，
        <span style="font-weight: 600">
          可以委托具备相关资格的司法鉴定机构
        </span>
        或者由国务院环境保护主管部门
        <span style="font-weight: 600">
          推荐的机构
        </span>
        进行专业的司法鉴定，解决环境损害后果难以评估鉴定的困境。
      </p>
      <p class="space_content">
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        运用科学技术和专门知识进行环境物证鉴定与环境损害鉴定评估，出具鉴定意见，可为环境案件的磋商、仲裁、行政执法与司法审判提供具有效能的证据
        <span style="font-weight: 600">
          （具备证据能力与证明力）。
        </span>
      </p>
      <p class="space_content">
        2006年，
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        取得国内首批环境物证鉴定牌照，为全国环境物证领域
        <span style="font-weight: 600">
          最早介入、最早研究、最早实践
        </span>
        的机构之一，先后承办了泉港皮革案群体性事件等福建重大特大环境案件。
      </p>
      <p class="space_content">
        在十多年来从事环境物证鉴定、环境污染损害鉴定评估等工作中，我所逐步建立了一支经验丰富的团队，由精通环境调查、环境评价、环境风险管理、环境司法、环境科研、环境监测领域的专家组成，其中不少专家曾在跨国公司多年从事环境调查。
      </p>

      <p class="space_content">
        2016年，
        <span style="font-weight: 600;color: red">
          福建历思司法鉴定所
        </span>
        进入环保部环境损害鉴定评估
        <span style="font-weight: 600;">
           推荐机构名录，
        </span>
        在全国29家单位中拥有环境物证司法鉴定和环保部环境损害鉴定评估推荐机构
        <span style="font-weight: 600;">
           双重资质。
        </span>

      </p>
      <p class="space_content">
        历思承担的《环境损害鉴定评估通用规范》福建省地方标准，于2017年12月发布并从 2018年3月25日起正式实施，该标准是目前
        <span style="font-weight: 600;">
             国内该领域首个标准。
        </span>

      </p>



    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/16'"><p class="ellipsis1">历思利用毛发检测，高新技术打破海南禁毒“滞案”僵局！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/18'"><p class="ellipsis1">
            厦门市公安局许标旗副局长一行莅临考察福建历思司法鉴定所</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
